<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $lang('编辑收货地址') }}</span>
            </div>

            <div v-loading="loading" class="ns-member-address-list">
                <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="120px">
                    <el-form-item :label="$lang('姓名')" prop="name">
                        <el-input v-model="formData.name" :placeholder="$lang('收货人姓名')" class="ns-len-input"></el-input>
                    </el-form-item>
                    <el-form-item :label="$lang('手机')" prop="mobile">
                        <el-input v-model="formData.mobile" autocomplete="off" :placeholder="$lang('收货人手机号')"
                            class="ns-len-input"></el-input>
                    </el-form-item>
                    <el-form-item :label="$lang('电话')">
                        <el-input v-model.trim="formData.telephone" autocomplete="off"
                            :placeholder="$lang('收货人固定电话（选填）')" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item :label="$lang('地址')" prop="full_address">
                        <el-select v-model="formData.province_id" :placeholder="$lang('请选择省')"
                            @change="changeProvice(formData.province_id)">
                            <el-option v-for="option in province" :key="option.id" :label="option.name"
                                :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <el-select v-model="formData.city_id" :placeholder="$lang('请选择市')"
                            @change="changeCity(formData.city_id)">
                            <el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">
                                {{ option.name }}</el-option>
                        </el-select>
                        <el-select v-model="formData.district_id" :placeholder="$lang('请选择区/县')"
                            @change="changeDistrict(formData.district_id)">
                            <el-option v-for="option in district" :key="option.id" :label="option.name"
                                :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$lang('定位')" prop="posLocation">
                        <span v-if="!formData.posLocation" @click="cation" class="poslocation">
                            {{ $lang('点击获取当前位置') }}
                        </span>
                        <span v-if="formData.posLocation" @click="cation" class="poslocation">
                            {{
                                    formData.posLocation
                            }}
                        </span>
                    </el-form-item>
                    <el-form-item :label="$lang('楼号-门牌号')" prop="address">
                        <el-input v-model.trim="formData.address" autocomplete="off"
                            :placeholder="$lang('定位到小区、街道、写字楼')" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item :label="$lang('是否默认')">
                        <el-radio-group v-model="formData.is_default">
                            <el-radio :label="1">{{ $lang('是') }}</el-radio>
                            <el-radio :label="0">{{ $lang('否') }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="flow" prop="imgs" :label="$lang('图片')">
                        <el-upload :action="uploadActionUrl" :show-file-list="false" :on-success="handleAvatarSuccess"
                            class="upload">
                            <div class="img-wrap">
                                <img :src="$img(formData.userHeadImg)" v-if="formData.userHeadImg"
                                    @error="formData.userHeadImg = defaultHeadImage" />
                                <div v-if="!formData.userHeadImg">+</div>
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="medium" @click="saveAddress('ruleForm')">{{ $lang('保存') }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import { addressInfo, saveAddress } from "@/api/member/member"
import { getArea } from "@/api/address"
import { mapGetters } from "vuex"
import Config from "@/utils/config"
export default {
    name: "address_edit",
    components: {},
    data() {
        let self = this
        var isMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("手机号不能为空"))
            } else {
                const reg = /^[0-9]{6,16}$/
                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error("请输入正确的手机号"))
                }
            }
        }

        var fullAddress = (rule, value, callback) => {
            if (self.formData.province_id) {
                if (self.formData.city_id) {
                    if (self.formData.district_id) {
                        return callback()
                    } else {
                        return callback(new Error("请选择区/县"))
                    }
                } else {
                    return callback(new Error("请选择市"))
                }
            } else {
                return callback(new Error("请选择省"))
            }
        }

        return {
            uploadActionUrl: Config.baseUrl + "/api/upload/headimg",

            formData: {
                id: 0,
                name: "",
                mobile: "",
                telephone: "",
                province_id: "",
                city_id: "",
                district_id: "",
                community_id: "",
                address: "",
                full_address: "",
                latitude: 0,
                longitude: 0,
                is_default: 1,
                userHeadImg: "",
                posLocation: ''
            },
            addressValue: "",
            flag: false, //防重复标识
            defaultRegions: [],
            rules: {
                name: [{ required: true, message: "请输入收货人姓名", trigger: "blur" }],
                mobile: [{ required: true, validator: isMobile, trigger: "blur" }],
                address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
                full_address: [{ required: true, validator: fullAddress, trigger: "blur" }],
                userHeadImg: [{ required: true, message: "请最少选一张图片", trigger: "blur" }]
            },
            province: [],
            city: [],
            district: [],
            pickerValueArray: [],
            multiIndex: [0, 0, 0],
            isInitMultiArray: false,
            // 是否加载完默认地区
            isLoadDefaultAreas: false,
            loading: true,
            yes: true,
            imgUrl: "",
            flow: false
        }
    },
    created() {

        this.formData.id = this.$route.query.id
        this.getAddressDetail()
        this.getDefaultAreas(0, {
            level: 0
        })
        if (this.$route.query.is_default) {
            this.formData = this.$route.query
        }
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    watch: {
        defaultRegions: {
            handler(arr, oldArr = []) {
                // 避免传的是字面量的时候重复触发
                if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
                this.handleDefaultRegions()
            },
            immediate: true
        }
    },
    computed: {
        pickedArr() {
            // 进行初始化
            if (this.isInitMultiArray) {
                return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
            }
            return [this.pickerValueArray[0], this.city, this.district]
        }
    },
    methods: {
        cation() {
            this.$router.push({ path: "/member/google_list", query: this.formData })
        },
        handleAvatarSuccess(res, file) {
            this.imgUrl = res.data.pic_path
            this.formData.userHeadImg = URL.createObjectURL(file.raw)
        },
        /**
         * 改变省
         */
        changeProvice(id) {
            this.getAreas(id, data => (this.city = data))
            let obj = {}
            console.log(obj)

            console.log(this.province)
            obj = this.province.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })

            if (obj.type == 1) {
                this.flow = true
            } else {
                this.flow = false
            }
            this.formData.city_id = ""
            this.formData.district_id = ""
            this.formData.full_address = obj.name // 设置选中的地址
        },
        /**
         * 改变市
         */
        changeCity(id) {
            this.getAreas(id, data => (this.district = data))
            let obj = {}
            obj = this.city.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.formData.district_id = ""
            this.formData.full_address = this.formData.full_address + "-" + obj.name
        },
        /**
         * 改变区
         */
        changeDistrict(id) {
            let obj = {}
            obj = this.district.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.formData.full_address = this.formData.full_address + "-" + obj.name
        },
        /**
         * 获取地址信息
         */
        getAddressDetail() {
            addressInfo({
                id: this.formData.id
            })
                .then(res => {
                    console.log(res)
                    let data = res.data
                    if (data != null) {
                        this.formData.name = data.name
                        this.formData.mobile = data.mobile
                        this.formData.telephone = data.telephone
                        this.formData.full_address = data.full_address
                        this.formData.latitude = data.latitude
                        this.formData.longitude = data.longitude
                        this.formData.is_default = data.is_default
                        this.formData.province_id = data.province_id
                        this.formData.city_id = data.city_id
                        this.formData.district_id = data.district_id
                        let resData = data.address.split('&')
                        this.formData.posLocation = resData[0]
                        this.formData.address = resData[1]
                        if (this.$route.query.name) {
                            this.formData.posLocation = this.$route.query.name
                        }
                        if (data.img) {
                            this.flow = true
                        } else {
                            this.flow = false
                        }
                        this.formData.userHeadImg = data.img
                        this.defaultRegions = [data.province_id, data.city_id, data.district_id]

                    }
                })
                .catch(err => { })
        },
        // 异步获取地区
        getAreas(pid, callback) {
            getArea({
                pid: pid
            })
                .then(res => {
                    if (res.code == 0) {
                        var data = []
                        res.data.forEach((item, index) => {
                            data.push(item)
                        })
                        if (callback) callback(data)
                    }
                })
                .catch(err => { })
        },

        /**
         * 获取省市区列表
         */
        getDefaultAreas(pid, obj) {
            getArea({
                pid: pid
            })
                .then(res => {
                    if (res.code == 0) {
                        var data = []
                        var selected = undefined
                        res.data.forEach((item, index) => {
                            if (obj != undefined) {
                                if (obj.level == 0 && obj.province_id != undefined) {
                                    selected = obj.province_id
                                } else if (obj.level == 1 && obj.city_id != undefined) {
                                    selected = obj.city_id
                                } else if (obj.level == 2 && obj.district_id != undefined) {
                                    selected = obj.district_id
                                }
                            }

                            if (selected == undefined && index == 0) {
                                selected = item.id
                            }
                            data.push(item)
                        })

                        this.pickerValueArray[obj.level] = data
                        if (obj.level + 1 < 3) {
                            obj.level++
                            this.getDefaultAreas(selected, obj)
                        } else {
                            this.isInitMultiArray = true
                            this.isLoadDefaultAreas = true
                        }

                        this.province = this.pickerValueArray[0]
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },

        /**
         * 渲染默认值
         */
        handleDefaultRegions() {
            var time = setInterval(() => {
                if (!this.isLoadDefaultAreas) return
                this.isInitMultiArray = false

                for (let i = 0; i < this.defaultRegions.length; i++) {
                    for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                        this.province = this.pickerValueArray[0]

                        // 匹配省
                        if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                            // 设置选中省
                            this.$set(this.multiIndex, i, j)
                            // 查询市
                            this.getAreas(this.pickerValueArray[i][j].id, data => {
                                this.city = data

                                for (let k = 0; k < this.city.length; k++) {
                                    if (this.defaultRegions[1] == this.city[k].id) {
                                        // 设置选中市
                                        this.$set(this.multiIndex, 1, k)

                                        // 查询区县
                                        this.getAreas(this.city[k].id, data => {
                                            this.district = data

                                            // 设置选中区县
                                            for (let u = 0; u < this.district.length; u++) {
                                                if (this.defaultRegions[2] == this.district[u].id) {
                                                    this.$set(this.multiIndex, 2, u)
                                                    this.handleValueChange({
                                                        detail: {
                                                            value: [j, k, u]
                                                        }
                                                    })
                                                    break
                                                }
                                            }
                                        })

                                        break
                                    }
                                }
                            })
                        }
                    }
                }
                if (this.isLoadDefaultAreas) clearInterval(time)
            }, 100)
        },
        handleValueChange(e) {
            // 结构赋值
            let [index0, index1, index2] = e.detail.value
            let [arr0, arr1, arr2] = this.pickedArr
            let address = [arr0[index0], arr1[index1], arr2[index2]]

            this.formData.full_address = ""
            for (let i = 0; i < address.length; i++) {
                if (this.formData.full_address) {
                    this.formData.full_address = this.formData.full_address + "-" + address[i].name
                } else {
                    this.formData.full_address = this.formData.full_address + address[i].name
                }
            }
        },

        /**
         * 保存地址
         */
        saveAddress(formName) {
            console.log(this.formData)
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.flow) {
                        var data = {
                            name: this.formData.name,
                            mobile: this.formData.mobile,
                            telephone: this.formData.telephone,
                            province_id: this.formData.province_id,
                            city_id: this.formData.city_id,
                            district_id: this.formData.district_id,
                            community_id: "",
                            address: this.formData.posLocation + '&' + this.formData.address,
                            full_address: this.formData.full_address,
                            latitude: this.formData.latitude,
                            longitude: this.formData.longitude,
                            is_default: this.formData.is_default,
                            uploadImg: Config.imgDomain + this.imgUrl
                        }
                    } else {
                        var data = {
                            name: this.formData.name,
                            mobile: this.formData.mobile,
                            telephone: this.formData.telephone,
                            province_id: this.formData.province_id,
                            city_id: this.formData.city_id,
                            district_id: this.formData.district_id,
                            community_id: "",
                            address: this.formData.posLocation + '&' + this.formData.address,
                            full_address: this.formData.full_address,
                            latitude: this.formData.latitude,
                            longitude: this.formData.longitude,
                            is_default: this.formData.is_default
                        }
                    }

                    data.url = "add"
                    if (this.formData.id) {
                        data.url = "edit"
                        data.id = this.formData.id
                    }
                    if (this.flag) return
                    this.flag = true

                    saveAddress(data)
                        .then(res => {
                            console.log(res)
                            if (res.code == 0) {
                                this.$router.push({ path: "/member/delivery_address" })
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        })
                        .catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
    box-shadow: unset;
}

.el-card {
    border: 0;
}

.ns-len-input {
    width: 350px;
}

.el-select {
    margin-right: 10px;
}

.upload {
    width: 108px;
    height: 108px;
    background: #eeeeee;
    border-radius: 8px;
    text-align: center;
    font-size: 40px;
    line-height: 108px;
}

.upload img {
    width: 108px;
    height: 108px;
}

.poslocation {
    cursor: pointer;
}

.poslocation:hover {
    color: #ff547b;
}
</style>
